
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import State from "@/components/reusable/State.vue";
import Gender from "@/components/reusable/Gender.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "cooperative-add-new-members",
  components: {
    ErrorMessage,
    Field,
    Form,
    Gender
  },
  data() {
    return {
      displayDates: false,
      newMemberDetails: {
        alternativePhoneNumber: "",
        avatar: "",
        dateOfBirth: "",
        emailAddress: "",
        firstName: "",
        gender: "",
        homeAddress: "",
        identificationExpiryDate: "",
        identificationIssueDate: "",
        identificationNumber: "",
        lastName: "",
        localGovernmentArea: "",
        maritalStatus: "",
        meansOfIdentification: "",
        middleName: "",
        phoneNumber: "",
        ward: "",
        password: "",
        confirmPassword: ""
      }
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);

    const newMemberDetailsValidator = Yup.object().shape({
      firstName: Yup.string().required().label("First name"),
      middleName: Yup.string().notRequired().label("First name"),
      lastName: Yup.string().required().label("Last name"),
      emailAddress: Yup.string().required().label("Email address"),
      phoneNumber: Yup.number().required().label("Phone number"),
      alternativePhoneNumber: Yup.string()
        .notRequired()
        .label("Alternative phone number"),
      dateOfBirth: Yup.date().required().label("Date of birth"),
      gender: Yup.mixed().required().label("Gender"),
      maritalStatus: Yup.mixed().required().label("Martial status"),
      state: Yup.mixed().required().label("State"),
      localGovernmentArea: Yup.mixed()
        .required()
        .label("Local government area"),
      meansOfIdentification: Yup.mixed()
        .notRequired()
        .label("Means of identification"),
      identificationNumber: Yup.string()
        .required()
        .label("Identification number"),
      identificationIssueDate: Yup.date().notRequired().label("Date of issue"),
      identificationExpiryDate: Yup.date()
        .notRequired()
        .label("Date of expiry"),
      homeAddress: Yup.string().required().label("Home address"),
      password: Yup.string().min(8).required().label("Password"),
      confirmPassword: Yup.string()
        .min(8)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Confirm Password")
    });

    const createMember = (values) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.patch(variables.PROFILE_PERSONAL_INFORMATION, values)
              .then((response) => {
                variables.toastAlert(response.data.message, "success");
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Add New", ["Cooperative", "Members"]);
    });

    return {
      submitButton1,
      createMember,
      newMemberDetailsValidator
    };
  },
  created() {
    //Set page title
    document.title =
      "Add New Cooperative Member | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    removeImage() {
      this.newMemberDetails.avatar = "../media/avatars/blank.png";
    },
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
    handleImageUpload(event) {
      const selectedImage = event.target.files[0]; //get the first file

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        this.newMemberDetails.avatar = reader.result as string;
      };

      reader.onerror = function (error) {
        variables.toastAlert(error, "error");
      };
    },
    identificationIssueDateAndExpiry(event) {
      const value = event.target.value;

      value == "Drivers License" || value == "International Passport"
        ? (this.displayDates = true)
        : (this.displayDates = false);
      const dateIssued = document.getElementById(
        "date-of-issue"
      ) as HTMLInputElement;
      const dateExpired = document.getElementById(
        "date-of-expiry"
      ) as HTMLInputElement;

      dateIssued.value != "" ? dateIssued.value : "";
      dateExpired.value != "" ? dateExpired.value : "";
    },
    generatePassword() {
      const passwordField = document.getElementById(
        "password"
      ) as HTMLInputElement;
      const confirmPasswordField = document.getElementById(
        "confirm-password"
      ) as HTMLInputElement;

      const generatedPassword = variables.generatePassword();
      passwordField.value = generatedPassword;
      confirmPasswordField.value = generatedPassword;
    }
  }
});
